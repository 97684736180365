<template>
  <Scaffold>
    <div class="page-content">
      <Part title="기본 정보">
        <template #buttons>
          <Button @click="onBack">뒤로</Button>
          <Button @click="onSubmit" type="primary">저장</Button>
        </template>

        <div class="part-content">
          <Form :label-width="120" label-position="right">
            <FormItem label="이름">
              <Input size="large" v-model="form.name" placeholder=""></Input>
            </FormItem>
            <FormItem label="아이디">
              <Input size="large" v-model="form.username" placeholder="휴대폰 번호 숫자만 입력"></Input>
            </FormItem>
            <FormItem label="테스트">
              <Checkbox v-model="form.is_test">테스트용 유저</Checkbox>
            </FormItem>
            <FormItem label="역할">
              <Select size="large" v-model="form.role" :disabled="form.role === 'master'">
                <Option v-for="r in ['normal', 'staff', 'admin', 'master']"
                        :value="r" :key="`role-option-${r}`"
                        :disabled="r === 'master'"
                >{{ r }}
                </Option>
              </Select>
            </FormItem>
            <FormItem label="환불 정보">
              <Button :to="{name: 'user-refund-info', params: {id: this.id}}">확인하기</Button>
            </FormItem>
          </Form>
        </div>
      </Part>
    </div>
  </Scaffold>
</template>

<script>
import {editorMixin} from "@/mixins/editor";

export default {
  props: ['id'],
  mixins: [editorMixin],
  computed: {
    apiUrl() {
      return `user/${this.id}`
    }
  },
  data() {
    return {
      form: {
        username: '',
        name: '',
        role: 'normal',
        is_test: false,
      }
    }
  },
  methods: {
    beforeSubmit() {
      if (this.form.password !== this.form.passwordRe) {
        this.messages.error('비밀번호가 일치하지 않습니다.')
        return false
      }
      return true
    }
  },
  mounted() {
    if (this.isModify) {
      this.getData()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
