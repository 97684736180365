export const editorMixin = {
  data() {
    return {
      form: {},
      meta: {},
      valid: {},
      errorMessage: '',
      loading: false,
    }
  },
  computed: {
    isModify() {
      return !!this.id && this.id !== 'new'
    }
  },
  methods: {
    checkValid() {

    },
    onBack() {
      this.$router.back()
    },
    getData() {
      this.loading = true
      this.$axios.$get(this.apiUrl).then(res => {
        this.form = res.result
        this.meta = res.meta
      }).finally(_ => {
        this.loading = false
      })
    },
    onSubmit() {
      if (!this.beforeSubmit()) {
        return ;
      }

      if (this.isModify) {
        this.onUpdate()
      } else {
        this.onCreate()
      }
    },
    onUpdate() {
      this.loading = true
      this.$axios.$patch(this.apiUrl, this.form).then(res => {
        this.messages.success('저장이 완료되었습니다.')
        this.afterSubmit(res)
      }).finally(_ => {
        this.loading = false
      })
    },
    onCreate() {
      this.loading = true
      this.$axios.$post(this.apiUrl, this.form).then(res => {
        this.messages.success('저장이 완료되었습니다.')
        this.afterSubmit(res)
        const {name, query, hash} = this.$route
        this.$router.replace({name, query, hash, params: {id: res.result.id}})
      }).finally(_ => {
        this.loading = false
      })
    },
    beforeSubmit() {
      return true;
    },
    afterSubmit(res) {
    }
  }
}